<template>
  <v-dialog
    :value="manageSettingsDialog.dialog"
    persistent
    max-width="600px"
  >
    <v-card>
        <v-card-title>
          <span class="headline">Editar información</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
              >
                <TextField 
                  :Value="navbarMenu.username"
                  Label="Nombre"
                  Type="text"
                  :Dark="false"
                  :Disabled="true"/>
              </v-col>
              <v-col cols="12">
                <TextField 
                  :Value="navbarMenu.email"
                  Label="Correo electrónico"
                  Type="text"
                  :Dark="false"
                  :Disabled="true"/>
              </v-col>
              <v-col cols="12">
                <PasswordField 
                  :Dark="false"
                  :Rules="manageSettingsDialog.passwordRules" 
                  Message="Min 8 characters" 
                  :Value="manageSettingsDialog.oldPassword" 
                  Label="Password anterior" :Fn="setOldPassword"/>
              </v-col>
              <v-col cols="12">
                <PasswordField 
                  :Dark="false"
                  :Rules="manageSettingsDialog.passwordRules" 
                  Message="Min 8 characters" 
                  :Value="manageSettingsDialog.newPassword" 
                  Label="Nueva contraseña" :Fn="setNewPassword"/>
              </v-col>
              
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeManageSettingDialog"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="changePassword"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapMutations, mapState} from 'vuex';
import PasswordField from '../../StandardizedComponents/passwordFields'
import TextField from '../../StandardizedComponents/textFields'

export default {
    components:{
      PasswordField,
      TextField
    },
    computed:{
      ...mapState("Navbar", ["navbarMenu"]),
      ...mapState('InternalUsers', ['manageSettingsDialog']),
    },
    methods:{
      ...mapMutations("InternalUsers", ['closeManageSettingDialog', 'setNewPassword', 'setOldPassword']),
      ...mapActions("InternalUsers", ["changePassword"])
    }
}
</script>