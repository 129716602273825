<template>
  <div>
    <v-app-bar color="black">
      <v-app-bar-nav-icon
        color="white"
        @click.stop="navigationDrawerStateModifier(true)"
        v-if="showDrawer"
      ></v-app-bar-nav-icon>
      <v-img
        alt="Radiotrunk Logos"
        src="../assets/Logo-Radio-Trunk_blanco.png"
        :width="iconDimension"
        min-width="50"
        max-width="180"
        v-if="!showDrawer"
      />
      <v-img
        alt="Radiotrunk Logos"
        src="../assets/Logo-Radio-Trunk_blanco.png"
        :width="iconDimension"
        min-width="50"
        max-width="150"
        v-if="showDrawer"
      />
      <v-tabs
        v-model="tab"
        centered
        center-active
        dark
        icons-and-text
        v-if="showMenu"
        fixed-tabs
      >
        <v-tab v-for="item in navbarMenu.menu" :to="item.link" :key="item.id">
          {{ item.title }}
          <v-icon>{{ item.icon }}</v-icon>
        </v-tab>
      </v-tabs>
      <v-spacer></v-spacer>
      <!-- <AccountNotifications/> -->
      <v-menu bottom min-width="200px" rounded offset-y v-if="showMenu">
        <template v-slot:activator="{ on }">
          <v-btn icon x-large v-on="on">
            <v-avatar color="primary" size="48">
              <span class="white--text headline">{{ navbarMenu.initial }}</span>
            </v-avatar>
          </v-btn>
        </template>
        <v-card>
          <v-list-item-content class="justify-center">
            <div class="mx-auto text-center">
              <v-avatar color="primary">
                <span class="white--text headline">{{ navbarMenu.initial }}</span>
              </v-avatar>
              <h3>{{ navbarMenu.username }}</h3>
              <p class="caption mt-1">
                {{ navbarMenu.email }}
              </p>
              <v-divider class="my-3"></v-divider>
              <v-switch
                v-model="$vuetify.theme.dark"
                inset
                class="ml-4"
                label="Modo oscuro"
                persistent-hint
              ></v-switch>
              <v-divider class="my-3"></v-divider>
              <v-btn depressed rounded text @click="showManageSettingDialog">
                Editar Cuenta
              </v-btn>
              <v-divider class="my-3"></v-divider>
              <v-btn depressed rounded text @click="logout">
                Cerrar Sesion
              </v-btn>
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer v-model="NavigationDrawer" absolute temporary>
      <template v-slot:prepend>
        <v-card flat>
          <div class="mx-auto text-center pt-2 pb-1">
            <v-avatar color="primary">
              <span class="white--text headline">{{ navbarMenu.initial }}</span>
            </v-avatar>
            <h3>{{ navbarMenu.username }}</h3>
            <p class="caption mt-1">
              {{ navbarMenu.email }}
            </p>
          </div>
        </v-card>
      </template>
      <v-list nav>
        <v-list-item-group
          v-model="group"
          active-class="primary--text text--accent-4"
        >
          <v-list-item
            v-for="item in navbarMenu.menu"
            :key="item.id"
            :to="item.link"
          >
            <v-icon>{{ item.icon }}</v-icon>
            <v-list-item-title class="pl-2">{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-divider class="my-3"></v-divider>
      <v-switch
        v-model="$vuetify.theme.dark"
        inset
        class="ml-4"
        label="Modo oscuro"
        persistent-hint
      ></v-switch>
      <v-divider class="my-3"></v-divider>
      <v-btn block color="primary" text @click="showManageSettingDialog"
        ><v-icon class="mr-2">mdi-cog</v-icon> Editar cuenta</v-btn
      >
      <v-btn block color="primary" class="mt-2" text @click="logout"
        ><v-icon class="mr-2">mdi-logout</v-icon> Cerrar sesión</v-btn
      >
    </v-navigation-drawer>
    <!--<ReactivateAccount/>
    <DeleteAccount :dialog="dialog" 
    :account_number="account_number" 
    :REMAINING_DAYS="REMAINING_DAYS" 
    :SN="SN" 
    :final_date="final_date"
    :closeDeleteDialog="closeDialogDelete"
    :deleteService="deleteAccountF"/>-->
    <EditAcount />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import EditAcount from "./Dialogs/UsersDialogs/editacount";
// import AccountNotifications from './Complements&Buttons/accountNotifications'

export default {
  name: "Navbar",
  components: {
    EditAcount,
    // AccountNotifications
  },
  created() {
    this.setUserAccountInformation({
      username: localStorage.getItem("name"),
      email: localStorage.getItem("email"),
    });
    this.setMenu();
  },
  computed: {
    group: {
      get() {
        return this.navbarMenu.group;
      },
      set(v) {
        this.setNavbarMenuGroup(v);
      },
    },
    NavigationDrawer: {
      get() {
        return this.navbarMenu.drawer;
      },
      set(v) {
        this.navigationDrawerStateModifier(v);
      },
    },
    tab: {
      get() {
        return this.navbarMenu.tab;
      },
      set(v) {
        this.setNavbarMenuTab(v);
      },
    },
    ...mapState("Navbar", ["navbarMenu"]),
    isLarge() {
      return (
        this.$vuetify.breakpoint.name !== "lg" &&
        this.$vuetify.breakpoint.name !== "xl" &&
        this.$vuetify.breakpoint.name !== "md"
      );
    },
    iconDimension() {
      if (this.showMenu) {
        return "250";
      } else {
        return "150";
      }
    },
    showDrawer() {
      return this.isLarge || this.drawer;
    },
    showMenu() {
      return this.$vuetify.breakpoint.name !== "xs";
    },
  },
  methods: {
    ...mapMutations("Navbar", [
      "navigationDrawerStateModifier",
      "setMenu",
      "setNavbarMenuTab",
      "setNavbarMenuGroup",
    ]),
    ...mapMutations("InternalUsers", ["showManageSettingDialog"]),
    ...mapActions("Navbar", ["setUserAccountInformation"]),
    async logout() {
      if (localStorage.token) {
        localStorage.removeItem("token");
        window.location.reload();
      } else {
        window.location.reload();
      }
    },
  },
};
</script>

